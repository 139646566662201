<template>
    <app-wrapper>
        <template v-slot:child>
            <!--payables, inport button, send statement & new -->
            <div class="flex flex-wrap justify-between items-center w-90 center pv4">
                <div>
                    <p class="pb3 f3 b">{{ entityValue }}</p>
                </div>
                <div class="scrollmenu">
                    <button class="btn2" disabled>Import</button>
                    <button class="btn2 ml2" disabled>Send statement</button>
                    <!--          <button class="ml2">New &nbsp; <img src="../../assets/images/down-o.svg" alt="" /></button>-->
                </div>
            </div>
            <!--payables , inport button, send statement & new -->

            <!-- payables, past dues, average payables , outstanding payables-->
            <div class="w-90 center flex flex-wrap pv4">
                <!-- Outstanding payables-->
                <div class="pv4 pl3 w-20-l w-50" style="border: 1px solid #e3e8ee">
                    <div>
                        <p class="pl2 lh-title">Outstanding payables</p>
                    </div>
                    <!--          <div class="flex pt3">-->
                    <!--            <div class="flex">-->
                    <!--              <img src="../../assets/images/increase.svg" alt="" />-->
                    <!--              <p class="pl2">21.9%</p>-->
                    <!--            </div>-->
                    <!--            <div class="pl2">+$67k today</div>-->
                    <!--          </div>-->
                    <div style="color: #132c8c">
                        <h3 class="mb0">{{ formatAmount(stats?.payablesAmount || 0, $store.state.Settings?.currency) }}</h3>
                    </div>
                </div>
                <!-- Outstanding payables -->

                <!-- Outstanding Receivables -->
                <div class="pv4 pl3 w-20-l w-50" style="border: 1px solid #e3e8ee">
                    <div>
                        <p class="lh-title">Outstanding Receivables</p>
                    </div>
                    <!--          <div class="flex pt3">-->
                    <!--            <div class="flex">-->
                    <!--              <img src="../../assets/images/increase.svg" alt="" />-->
                    <!--              <p class="pl2">21.9%</p>-->
                    <!--            </div>-->
                    <!--            <div class="pl2">+$67k today</div>-->
                    <!--          </div>-->
                    <div style="color: #132c8c">
                        <h3 class="mb0">{{ formatAmount(stats?.receivablesAmount || 0, $store.state.Settings?.currency) }}</h3>
                    </div>
                </div>
                <!-- Outstanding Receivables-->

                <!-- Past dues -->
                <div class="pv4 pl3 w-20-l w-50" style="border: 1px solid #e3e8ee">
                    <div>
                        <p class="lh-title">Past Due Receivables</p>
                    </div>
                    <!--          <div class="flex pt3">-->
                    <!--            <div class="flex">-->
                    <!--              <img src="../../assets/images/increase.svg" alt="" />-->
                    <!--              <p class="pl2">21.9%</p>-->
                    <!--            </div>-->
                    <!--            <div class="pl2">+$67k today</div>-->
                    <!--          </div>-->
                    <div style="color: #132c8c">
                        <h3 class="mb0">{{ formatAmount(stats?.pastDueAmount || 0, $store.state.Settings?.currency) }}</h3>
                    </div>
                </div>
                <!-- Past dues-->

                <!-- Falling due soon-->
                <div class="pv4 pl3 w-20-l w-50" style="border: 1px solid #e3e8ee">
                    <div>
                        <p class="lh-title">Falling due soon Receivables</p>
                    </div>
                    <!--          <div class="flex pt3">-->
                    <!--            <div class="flex">-->
                    <!--              <img src="../../assets/images/desend.svg" alt="" />-->
                    <!--              <p class="pl2">21.9%</p>-->
                    <!--            </div>-->
                    <!--            <div class="pl2">+$67k today</div>-->
                    <!--          </div>-->
                    <div style="color: #132c8c">
                        <h3 class="mb0">{{ formatAmount(stats?.dueSoonAmount || 0, $store.state.Settings?.currency) }}</h3>
                    </div>
                </div>
                <!-- Falling due soon -->

                <!-- Average payable daily-->
                <div class="pv4 pl3 w-20-l w-50" style="border: 1px solid #e3e8ee">
                    <div>
                        <p class="ttc lh-title">Average Receivable Days</p>
                    </div>
                    <!--         <div class="flex pt3">-->
                    <!--          <div class="flex">-->
                    <!--           <img src="../../assets/images/desend.svg" alt="" />-->
                    <!--              <p class="pl2">21.9%</p>-->
                    <!--            </div>-->
                    <!--            <div class="pl2">+$67k today</div>-->
                    <!--          </div>-->
                    <div style="color: #132c8c">
                        <!--            <h3 class="mb0">$300.75</h3>-->
                        <h3 class="mb0 lh-title">Coming Soon</h3>
                    </div>
                </div>
                <!-- Average payable daily -->
            </div>
            <!-- payables, past dues, average payables , outstanding payables-->

            <!-- payables graph and receivable graph -->

            <!--      TODO Graphs-->

            <div class="flex flex-wrap justify-between w-90 center pv4">
                <!-- &lt;!&ndash; Payables  &ndash;&gt; -->
                <div v-if="isDebtorCustomRolePermitted" class="w-50-l w-50 pr3-l pr0 box-border mb0-l mb4">
                    <div class="">
                        <div class="flex justify-between items-center pa3 box-border-bottom">
                            <p class="b">Payables</p>
                            <p>
                                <select name="" id="payables" v-model="state.payablesFilter">
                                    <option value="last30Days">Last 30days</option>
                                    <option value="last7Days">Last 7days</option>
                                </select>
                            </p>
                        </div>
                    </div>

                    <div class="">
                        <div class="pa3">
                            <!-- <div class="flex justify-between">
                           <p class="w-20">$2,600</p>
                           <div class="flex items-center">
                             <p>Last month $23,000</p>
                             <p class="pl2 flex items-center">12.6% <img src="../../assets/images/increase-bg.svg" alt="" /></p>
                           </div>
                         </div> -->
                            <!-- //<img src="../../assets/images/expense-graph.png" class="pt3" alt="" /> -->
                            <Line
                                :chart-options="chartOptions"
                                :chart-data="payableLineData"
                                :chart-id="lineId"
                                :plugins="plugins"
                                :css-classes="cssClasses"
                                :styles="styles"
                                :width="width"
                                :height="height"
                            />
                        </div>
                    </div>
                </div>
                <!-- &lt;!&ndash; Payables&ndash;&gt; -->

                <!-- &lt;!&ndash; Receivables  &ndash;&gt; -->
                <div v-if="isCreditorCustomRolePermitted" class="w-50-l w-50 pl3-l pl0">
                    <div class="">
                        <div class="flex justify-between items-center pa3" style="border: 1px solid #e3e8ee">
                            <p class="b">Receivables</p>
                            <p>
                                <select name="" id="receivables" style="padding: 5px" v-model="state.receiveablesFilter">
                                    <option value="last30Days">Last 30days</option>
                                    <option value="last7Days">Last 7days</option>
                                </select>
                            </p>
                        </div>
                    </div>

                    <div class="">
                        <div class="pa3" style="border: 1px solid #e3e8ee">
                            <!-- <div class="flex justify-between">
                           <p class="w-20">$2,600</p>
                           <div class="flex items-center">
                             <p>Last month $21,200</p>
                             <p class="pl2 flex items-center">12.6% <img src="../../assets/images/decrease-bg.svg" alt="" /></p>
                           </div>
                         </div> -->
                            <!-- <img src="../../assets/images/expense-graph.png" class="pt3" alt="" /> -->
                            <Line
                                :chart-options="chartOptions"
                                :chart-data="receiveableLineData"
                                :chart-id="lineId"
                                :plugins="plugins"
                                :css-classes="cssClasses"
                                :styles="styles"
                                :width="width"
                                :height="height"
                            />
                        </div>
                    </div>
                </div>
                <!-- &lt;!&ndash; receivable graphs &ndash;&gt; -->
            </div>

            <!-- payables graph and receivable graph -->

            <PnRTable
                :receivables="receivables"
                :payables="payables"
                :onSwitchViewControlNavigation="onSwitchViewTableShowAccuratePagination"
                :isDebtorCustomRolePermitted="state.isDebtorCustomRolePermitted"
                :isCreditorCustomRolePermitted="state.isCreditorCustomRolePermitted"
            />

            <!-- pagination -->

            <template v-if="state.receivables && state?.receivables?.length">
                <Pagination :pages="receivables?.pagination" route-name="PnR" :onNavigate="onNavigateReceivables" />
            </template>

            <template v-if="state.payables && state?.payables?.length">
                <Pagination :pages="receivables?.pagination" route-name="PnR" :onNavigate="onNavigateReceivables" />
            </template>

            <!-- pagination -->
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onMounted, reactive, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppWrapper from '@/layout/AppWrapper'
import { formatAmount, formatDate, getLastSevenDaysDateRange } from '@/utils/lib'
import PnRTable from '@/views/PayablesAndReceiables/widgets/PnRTable'
import Pagination from '@/components/Pagination'
import { Bar, Line } from 'vue-chartjs'
import Swal from 'sweetalert2'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Plugin,
    Filler
} from 'chart.js'

ChartJS.register(Filler, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement)

// import Pagination from '@/components/Pagination'

export default {
    name: 'PayablesAndReceivables',
    components: { Pagination, AppWrapper, PnRTable, Bar, Line },

    setup() {
        const store = useStore()
        const route = useRoute()

        const stats = computed(() => store?.state.PnRModule.stats)
        const receivables = computed(() => store?.state.PnRModule.receivables)
        const payables = computed(() => store?.state.PnRModule.payables)
        const receivablesGraph = computed(() => store?.state.PnRModule.receivablesGraph)
        const payablesGraph = computed(() => store?.state.PnRModule.payablesGraph)
        const entityValue = ref(null)

        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)

        const state = reactive({
            receivables: true,
            payables: false,
            payableOverviewData: {},
            receiveableOverviewData: {},
            payablesFilter: 'last30Days',
            receiveablesFilter: 'last30Days',
            isDebtorCustomRolePermitted: true,
            isCreditorCustomRolePermitted: true
        })

        watch(
            () => rolePermissions.value,
            (currValue, prevValue) => {
                // console.log('current value', currValue)
                state.isDebtorCustomRolePermitted = currValue?.length > 0 && currValue.includes(10)
                state.isCreditorCustomRolePermitted = currValue?.length > 0 && currValue.includes(11)
            }
        )

        const onSwitchViewTableShowAccuratePagination = tableName => {
            const tables = Object.keys(state)
            // console.log(tables, tableName)
            tables.forEach(stateKey => {
                if (tableName === stateKey) {
                    state[tableName] = true
                } else {
                    // state[stateKey] = false
                }
            })
        }

        watch(
            () => receivablesGraph.value,
            (prevValue, newValue) => {
                const { data } = prevValue
                state.receiveableOverviewData = {
                    labels: data.days,
                    datasets: [
                        {
                            label: 'Receivables',
                            backgroundColor: '#f8fafe',
                            borderColor: '#f8fafe',
                            borderWidth: 1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 5,
                            pointHoverRadius: 5,
                            tension: 0.5,
                            data: data.data
                        }
                    ]
                }
            }
        )

        watch(
            () => payablesGraph.value,
            (prevValue, newValue) => {
                const { data } = prevValue
                // console.log(newValue, prevValue, data)
                state.payableOverviewData = {
                    labels: data.days,
                    datasets: [
                        {
                            label: 'Payables',
                            backgroundColor: '#f8fafe',
                            borderColor: '#f8fafe',
                            borderWidth: 1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 5,
                            pointHoverRadius: 5,
                            tension: 0.5,
                            data: data.data
                        }
                    ]
                }
            }
        )
        const receiveableLineData = computed(() => state.receiveableOverviewData)
        const payableLineData = computed(() => state.payableOverviewData)

        const chartOptions = computed(() => {
            return {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            // fontColor: 'rgba(0, 0, 0, 0.6)',
                            fontFamily: 'Circular Std Book',
                            fontSize: 12,
                            fontStyle: 'normal',
                            fontWeight: 200,
                            fontStretch: 'normal',
                            lineHeight: 1
                        }
                    }
                },
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false
                            //   drawBorder: BORDER,
                            //   drawOnChartArea: CHART_AREA,
                            //   drawTicks: TICKS,
                        }
                    },
                    y: {
                        grid: {
                            display: true,
                            borderWidth: 1,
                            color: '#e9eaee'

                            //   drawBorder: false,
                            //   color: function(context) {
                            //     if (context.tick.value > 0) {
                            //       return Utils.CHART_COLORS.green;
                            //     } else if (context.tick.value < 0) {
                            //       return Utils.CHART_COLORS.red;
                            //     }

                            //     return '#000000';
                            //   },
                        },
                        beginAtZero: true
                    }
                }
            }
        })

        watch(
            () => state.payablesFilter,
            (value, prev) => {
                // console.log(value, prev)
                if (value === 'last7Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange()
                    store.dispatch('PnRModule/getPayablesGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'payables'
                    })
                    //Swal.showLoading()
                } else if (value === 'last30Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange(30)
                    store.dispatch('PnRModule/getPayablesGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'payables'
                    })
                }
            }
        )

        watch(
            () => state.receiveablesFilter,
            (value, prev) => {
                console.log(value, prev)
                if (value === 'last7Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange()
                    store.dispatch('PnRModule/getReceivableGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'receiveables'
                    })
                } else if (value === 'last30Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange(30)
                    store.dispatch('PnRModule/getReceivableGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'receiveables'
                    })
                }
            }
        )

        onMounted(() => {
            const params = new URLSearchParams(window.location.search)
            let type = params.get('name') || 'Debtors & Creditors'

            entityValue.value = type.charAt(0).toUpperCase() + type.slice(1);
        })

        onMounted(() => {
            const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
            const { startDate, endDate } = getLastSevenDaysDateRange(30)

            store.dispatch('PnRModule/getPayablesGraphData', {
                startDate: startDate,
                endDate: endDate,
                type: 'payables'
            })
            store.dispatch('PnRModule/getReceivableGraphData', {
                startDate: startDate,
                endDate: endDate,
                type: 'receiveables'
            })
            store.dispatch('PnRModule/getStats')
            store.dispatch('PnRModule/getReceivables', query)
            store.dispatch('PnRModule/getPayables', query)
        })

        const onNavigatePayables = query => {
            store.dispatch('PnRModule/getPayables', query)
        }

        const onNavigateReceivables = query => {
            store.dispatch('PnRModule/getReceivables', query)
        }

        return {
            state,
            stats,
            onNavigatePayables,
            onNavigateReceivables,
            onSwitchViewTableShowAccuratePagination,
            receivables,
            payables,
            formatAmount,
            formatDate,
            chartOptions,
            payableLineData,
            receiveableLineData,
            entityValue
        }
    }
}
</script>

<style scoped></style>
