<template>
    <DnCRecordPaymentModal :closeModal="() => toggleDnCRecordPaymentModal(false)" @onComplete="refreshHandler" />
    <div class="w-90 center mb3">
        <div
            class="pv3 flex flex-wrap justify-between items-center"
            style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
        >
            <div class="flex justify-between w-20-l w-60">
                <p
                    v-if="rolePermissions?.includes(10) || role === 'owner' || role === 'admin'"
                    class="pointer"
                    :class="isCreditor === false && 'active-menu'"
                    @click.prevent="
                        () => {
                            toggleTable()
                            onSwitchViewControlNavigation('receivables')
                        }
                    "
                >
                    Debtors
                </p>
                <!-- <button
                    v-if="rolePermissions?.includes(10) || role === 'owner' || role === 'admin'"
                    class="buttonPnR"
                    :class="isCreditor === false && 'active-menu'"
                    :disabled="!isCreditor && (!rolePermissions?.includes(11) || role === 'owner' || role === 'admin')"
                    @click.prevent="
                        () => {
                            toggleTable()
                            onSwitchViewControlNavigation('receivables')
                        }
                    "
                >
                    Debtors
                </button> -->
                <p
                    v-if="rolePermissions?.includes(11) || role === 'owner' || role === 'admin'"
                    class="pointer"
                    :class="isCreditor === true && 'active-menu'"
                    @click.prevent="
                        () => {
                            toggleTable()
                            onSwitchViewControlNavigation('payables')
                        }
                    "
                >
                    Creditors
                </p>
                <!-- <button
                    v-if="rolePermissions?.includes(11) || role === 'owner' || role === 'admin'"
                    class="buttonPnR"
                    :class="isCreditor === true && 'active-menu'"
                    :disabled="isCreditor && (!rolePermissions?.includes(10) || role === 'owner' || role === 'admin')"
                    @click.prevent="
                        () => {
                            toggleTable()
                            onSwitchViewControlNavigation('payables')
                        }
                    "
                >
                    Creditors
                </button> -->
            </div>
            <!--      <div class="flex flex-wrap justify-between items-center w-60-l w-100 pt0-l pt3">-->
            <!--        <div class="card-box1">-->
            <!--          <input type="date" name="" class="input-style w-100" placeholder="Search..." />-->
            <!--        </div>-->
            <!--        <div class="searchmenu card-box1">-->
            <!--          <img src="../../../assets/images/search.svg" class="pa1" alt="search icon" />-->
            <!--          <input class="searchBox pl2" type="text" name="" placeholder="Search..." />-->
            <!--        </div>-->
            <!--        <div class="filter card-box1">-->
            <!--          <div class="tc flex items-center ph3">-->
            <!--            <img src="../../../assets/images/filter_list.svg" alt="filter icon" /> &nbsp;-->
            <!--            <p class="pl2">Filter</p>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--      </div>-->
        </div>
    </div>
    <!-- receivables, payable, search, date, filter -->

    <template v-if="!payables || !receivables"><Spinner /></template>

    <div v-if="payables || receivables" class="scrollmenu w-90 center">
        <table class="w-100 tl mt3" cellspacing="0">
            <thead>
                <tr>
                    <th class="pb3 box-border-bottom tl"></th>
                    <th class="pb3 box-border-bottom tl">
                        <span class="flex items-center">
                            <span>Number</span>
                            <span>
                                <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                    <span class="expand sort1" v-if="state.isOpen" @click="sortTransactionsTable('asc', '_id')">
                                        <img
                                            :src="require('@/assets/images/sort-up.png')"
                                            class="directional-up"
                                            style="height: 8px; width: 7px"
                                        />
                                    </span>
                                    <span class="expand sort1" v-if="!state.isOpen" @click="sortTransactionsTable('desc', '_id')">
                                        <img
                                            :src="require('@/assets/images/caret-down.png')"
                                            class="directional-up"
                                            style="height: 8px; width: 7px"
                                        />
                                    </span>
                                </span>
                            </span>
                        </span>
                    </th>

                    <th class="pb3 box-border-bottom tl pr2">
                        <span class="flex items-center">
                            <span>Customer Name</span>
                            <span>
                                <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                    <span
                                        class="expand sort1"
                                        v-if="state.isOpen"
                                        @click="sortTransactionsTable('asc', 'fullName')"
                                    >
                                        <img
                                            :src="require('@/assets/images/sort-up.png')"
                                            class="directional-up"
                                            style="height: 8px; width: 7px"
                                        />
                                    </span>
                                    <span
                                        class="expand sort1"
                                        v-if="!state.isOpen"
                                        @click="sortTransactionsTable('desc', 'fullName')"
                                    >
                                        <img
                                            :src="require('@/assets/images/caret-down.png')"
                                            class="directional-up"
                                            style="height: 8px; width: 7px"
                                        />
                                    </span>
                                </span>
                            </span>
                        </span>

                        <!-- <span class="sort1">
                            <img :src="require('@/assets/images/directional-up-arrow-svgrepo-com.svg')" class="directional-up" />
                        </span>
                        <span class="sort1">
                            <img :src="require('@/assets/images/down-arrow-direction-svgrepo-com.svg')" class="directional-up"
                        /></span> -->
                    </th>
                    <th class="pb3 pr3 box-border-bottom tl">
                        <span class="flex items-center">
                            <span>Total Amount</span>
                            <span>
                                <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                    <span
                                        class="expand sort1"
                                        v-if="state.isOpen"
                                        @click="sortTransactionsTable('asc', 'totalOwed')"
                                    >
                                        <img
                                            :src="require('@/assets/images/sort-up.png')"
                                            class="directional-up"
                                            style="height: 8px; width: 7px"
                                        />
                                    </span>
                                    <span
                                        class="expand sort1"
                                        v-if="!state.isOpen"
                                        @click="sortTransactionsTable('desc', 'totalOwed')"
                                    >
                                        <img
                                            :src="require('@/assets/images/caret-down.png')"
                                            class="directional-up"
                                            style="height: 8px; width: 7px"
                                        />
                                    </span>
                                </span>
                            </span>
                        </span>
                    </th>
                    <th class="pb3 box-border-bottom tl">
                        <span class="flex items-center">
                            <span>Outstanding Balance</span>
                            <span>
                                <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                    <span
                                        class="expand sort1"
                                        v-if="state.isOpen"
                                        @click="sortTransactionsTable('asc', 'totalBalance')"
                                    >
                                        <img
                                            :src="require('@/assets/images/sort-up.png')"
                                            class="directional-up"
                                            style="height: 8px; width: 7px"
                                        />
                                    </span>
                                    <span
                                        class="expand sort1"
                                        v-if="!state.isOpen"
                                        @click="sortTransactionsTable('desc', 'totalBalance')"
                                    >
                                        <img
                                            :src="require('@/assets/images/caret-down.png')"
                                            class="directional-up"
                                            style="height: 8px; width: 7px"
                                        />
                                    </span>
                                </span>
                            </span>
                        </span>
                    </th>
                    <th class="pb3 box-border-bottom tl">Action</th>
                </tr>
            </thead>
            <!-- <button v-if="isCreditor">Creditor</button>
            <button v-if="!isCreditor">Not Creditor</button> -->

            <template v-if="!isCreditor && (rolePermissions?.includes(10) || role === 'owner' || role === 'admin')">
                <template v-for="item of receivables?.data" v-bind:key="item._id">
                    <tbody>
                        <tr class="font-w1">
                            <td class="pv3 pr3 box-border-bottom tl" @click.prevent="toggleTableChild(item._id + 'receivables')">
                                <img
                                    v-if="!state.collapsable[item._id + 'receivables']"
                                    class=""
                                    style="all: revert"
                                    src="../../../assets/images/arrow-right.svg"
                                    alt=""
                                />
                                <img
                                    v-if="state.collapsable[item._id + 'receivables']"
                                    class=""
                                    style="all: revert"
                                    src="../../../assets/images/arrow-down.svg"
                                    alt=""
                                />
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ item?._id?.slice(11, item?._id?.length).toUpperCase() }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                <router-link :to="{ name: 'Entity', params: { id: item._id } }">{{
                                    item?.fullName || item?.company
                                }}</router-link>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.totalOwed, $store.state.Settings?.currency) }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.totalBalance, $store.state.Settings?.currency) }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                <a
                                    href=""
                                    @click.prevent="toggleDnCRecordPaymentModal(true, 'debt_repayment', item)"
                                    class="mr2"
                                    v-tippy="'Dept Repayment'"
                                >
                                    <img :src="require('@/assets/images/buyer-pay-icon.svg')" alt="debt_repayment" width="16" />
                                </a>
                            </td>
                        </tr>
                    </tbody>

                    <PnRSubTable :is-open="state.collapsable[item._id + 'receivables']" :transactions="item?.transactions" />
                </template>
            </template>

            <template v-if="isCreditor && (rolePermissions?.includes(11) || role === 'owner' || role === 'admin')">
                <template v-for="item of payables?.data" v-bind:key="item._id">
                    <tbody>
                        <tr class="font-w1">
                            <td class="pv3 pr3 box-border-bottom tl" @click.prevent="toggleTableChild(item._id + payables)">
                                <img
                                    v-if="!state.collapsable[item._id + payables]"
                                    class=""
                                    style="all: revert"
                                    src="../../../assets/images/arrow-right.svg"
                                    alt=""
                                />
                                <img
                                    v-if="state.collapsable[item._id + payables]"
                                    class=""
                                    style="all: revert"
                                    src="../../../assets/images/arrow-down.svg"
                                    alt=""
                                />
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ item?._id?.slice(11, item?._id?.length).toUpperCase() }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                <router-link :to="{ name: 'Entity', params: { id: item._id } }">{{
                                    item?.fullName || item?.company
                                }}</router-link>
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.totalOwe, $store.state.Settings?.currencyd) }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                {{ formatAmount(item?.totalBalance, $store.state.Settings?.currency) }}
                            </td>
                            <td class="pv3 pr3 box-border-bottom tl">
                                <a
                                    href=""
                                    @click.prevent="toggleDnCRecordPaymentModal(true, 'credit_repayment', item)"
                                    class="mr2"
                                    v-tippy="'Credit Repayment'"
                                >
                                    <img :src="require('@/assets/images/buyer-pay-icon.svg')" alt="credit_repayment" width="16" />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <PnRSubTable :is-open="state.collapsable[item._id + payables]" :transactions="item?.transactions" />
                </template>
            </template>
        </table>

        <div v-if="isCreditor">
            <Pagination
                v-if="payables?.data?.length"
                :pages="payables?.pagination"
                route-name="PnR"
                :onNavigate="onNavigate"
                class="w-100"
            />
        </div>
        <div v-else>
            <Pagination
                v-if="receivables?.data?.length"
                :pages="receivables?.pagination"
                route-name="PnR"
                :onNavigate="onNavigate"
                class="w-100"
            />
        </div>

        <template v-if="!isCreditor"><TableEmptyState :data-array="receivables" /></template>
        <template v-if="isCreditor"><TableEmptyState :data-array="payables" /></template>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watchEffect } from 'vue'
import { formatAmount, formatDate, sortByNumericData, sortAlphaNumericData } from '@/utils/lib'
import PnRSubTable from '@/views/PayablesAndReceiables/widgets/PnRSubTable'
import TableEmptyState from '@/components/TableEmptyState'
import DnCRecordPaymentModal from '@/views/PayablesAndReceiables/widgets/DnCRecordPaymentModal'
import Spinner from '@/components/Spinner'
import Pagination from '@/components/Pagination'
import { useStore } from 'vuex'

export default {
    name: 'PnRTable',
    components: { TableEmptyState, DnCRecordPaymentModal, PnRSubTable, Spinner, Pagination },
    props: {
        payables: Array,
        receivables: Array,
        onSwitchViewControlNavigation: Function,
        isDebtorCustomRolePermitted: Boolean,
        isCreditorCustomRolePermitted: Boolean,
    },

    setup(props) {
        const isCreditor = ref(false)
        const store = useStore()
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const state = reactive({
            isOpen: false,
            isLastTable: false,
            collapsable: [],
        })

        const toggleTableChild = (index) => {
            // state.isOpen = !state.isOpen

            if (!state.collapsable[index]) {
                state.collapsable[index] = false
            }

            state.collapsable[index] = !state.collapsable[index]
        }

        const toggleTable = () => {
            isCreditor.value = !isCreditor.value
        }

        // watchEffect(() => {
        //     if (store?.state?.Auth?.rolePermissions?.includes(10) || role === 'owner' || role === 'admin') {
        //         isCreditor.value = true
        //     }
        //     if (store?.state?.Auth?.rolePermissionss?.includes(11) || role === 'owner' || role === 'admin') {
        //         isCreditor.value = false
        //     }
        // })

        // This function takes in functions and call them when needed
        const parentFunction = (internalFunction, externalFunction) => {
            internalFunction()
            externalFunction()
        }

        const sortTransactionsTable = (order, value) => {
            let query
            if (state.isLastTable) {
                query = {
                    data: props.payables.data,
                    order: order,
                    value: value,
                }
            } else {
                query = {
                    data: props.receivables.data,
                    order: order,
                    value: value,
                }
            }

            if (value === 'totalOwed' || value === 'totalBalance') sortByNumericData(query)
            else {
                sortAlphaNumericData(query)
            }
        }

        const onNavigate = (query) => {
            // query.limit = 1
            if (isCreditor.value) {
                store.dispatch('PnRModule/getPayables', query)
            } else {
                store.dispatch('PnRModule/getReceivables', query)
            }
        }

        const toggleDnCRecordPaymentModal = (status, subtype, entity = null) => {
            store.dispatch('PnRModule/toggleDnCRecordPaymentModal', { status, subtype, entity })
        }

        onMounted(() => {
            const params = new URLSearchParams(window.location.search)
            let type = params.get('group')
            if (type === 'creditors') {
                toggleTable()
                props.onSwitchViewControlNavigation('payables')
            }
        })
        return {
            state,
            toggleTableChild,
            parentFunction,
            formatAmount,
            formatDate,
            sortTransactionsTable,
            rolePermissions,
            role,
            isCreditor,
            toggleTable,
            onNavigate,
            toggleDnCRecordPaymentModal,
        }
    },
}
</script>

<style scoped></style>
