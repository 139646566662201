<template>
    <template v-if="isOpen">
        <tbody class="dropdown" style="background-color: #f7f9fd">
            <tr class="font-w2">
                <td class="pv3 box-border-bottom tl">
                    <span class="flex items-center">
                        <span>Invoice Date</span>
                        <span>
                            <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                <span class="expand sort1" v-if="state.isOpen" @click="sortTransactionsTable('asc', 'date')">
                                    <img
                                        :src="require('@/assets/images/sort-up.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                        alt=""
                                    />
                                </span>
                                <span class="expand sort1" v-if="!state.isOpen" @click="sortTransactionsTable('desc', 'date')">
                                    <img
                                        :src="require('@/assets/images/caret-down.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                        alt=""
                                    />
                                </span>
                            </span>
                        </span>
                    </span>
                </td>
                <td class="pv3 box-border-bottom tl pr2">
                    <span class="flex items-center justify-between">
                        <span>Transaction Type</span>
                        <span>
                            <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                <span class="expand sort1" v-if="state.isOpen" @click="sortTransactionsTable('asc', 'type')">
                                    <img
                                        :src="require('@/assets/images/sort-up.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                        alt=""
                                    />
                                </span>
                                <span class="expand sort1" v-if="!state.isOpen" @click="sortTransactionsTable('desc', 'type')">
                                    <img
                                        :src="require('@/assets/images/caret-down.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                        alt=""
                                    />
                                </span>
                            </span>
                        </span>
                    </span>
                </td>
                <td class="pv3 box-border-bottom tl">
                    <span class="flex items-center">
                        <span>Description</span>
                        <span>
                            <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                <span
                                    class="expand sort1"
                                    v-if="state.isOpen"
                                    @click="sortTransactionsTable('asc', 'productName')"
                                >
                                    <img :src="require('@/assets/images/sort-up.png')" class="directional-up" alt="" />
                                </span>
                                <span
                                    class="expand sort1"
                                    v-if="!state.isOpen"
                                    @click="sortTransactionsTable('desc', 'productName')"
                                >
                                    <img :src="require('@/assets/images/caret-down.png')" class="directional-up" alt="" />
                                </span>
                            </span>
                        </span>
                    </span>
                </td>
                <td class="pv3 box-border-bottom tl">
                    <span class="flex items-center">
                        <span>Invoice Amount</span>
                        <span>
                            <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                <span class="expand sort1" v-if="state.isOpen" @click="sortTransactionsTable('asc', 'amount')">
                                    <img :src="require('@/assets/images/sort-up.png')" class="directional-up" alt="" />
                                </span>
                                <span class="expand sort1" v-if="!state.isOpen" @click="sortTransactionsTable('desc', 'amount')">
                                    <img :src="require('@/assets/images/caret-down.png')" class="directional-up" alt="" />
                                </span>
                            </span>
                        </span>
                    </span>
                </td>
                <td class="pv3 box-border-bottom tl">
                    <span class="flex items-center">
                        <span>Amount Paid</span>
                        <span>
                            <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                <span
                                    class="expand sort1"
                                    v-if="state.isOpen"
                                    @click="sortTransactionsTable('asc', 'amountPaid')"
                                >
                                    <img
                                        :src="require('@/assets/images/sort-up.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                    />
                                </span>
                                <span
                                    class="expand sort1"
                                    v-if="!state.isOpen"
                                    @click="sortTransactionsTable('desc', 'amountPaid')"
                                >
                                    <img
                                        :src="require('@/assets/images/caret-down.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                    />
                                </span>
                            </span>
                        </span>
                    </span>
                </td>
                <td class="pv3 pr3 box-border-bottom tl">
                    <span class="flex items-center">
                        <span>Outstanding Bal.</span>
                        <span>
                            <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                <span
                                    class="expand sort1"
                                    v-if="state.isOpen"
                                    @click="sortTransactionsTable('asc', 'amountOwed')"
                                >
                                    <img
                                        :src="require('@/assets/images/sort-up.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                    />
                                </span>
                                <span
                                    class="expand sort1"
                                    v-if="!state.isOpen"
                                    @click="sortTransactionsTable('desc', 'amountOwed')"
                                >
                                    <img
                                        :src="require('@/assets/images/caret-down.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                    />
                                </span>
                            </span>
                        </span>
                    </span>
                </td>
                <td class="pv3 box-border-bottom tl">
                    <span class="flex items-center">
                        <span>Due Date</span>
                        <span>
                            <span class="header-filter-sort-icon" @click="state.isOpen = !state.isOpen">
                                <span class="expand sort1" v-if="state.isOpen" @click="sortTransactionsTable('asc', 'dueDate')">
                                    <img
                                        :src="require('@/assets/images/sort-up.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                    />
                                </span>
                                <span class="expand sort1" v-if="!state.isOpen" @click="sortTransactionsTable('desc', 'dueDate')">
                                    <img
                                        :src="require('@/assets/images/caret-down.png')"
                                        class="directional-up"
                                        style="height: 8px; width: 7px;"
                                    />
                                </span>
                            </span>
                        </span>
                    </span>
                </td>
            </tr>

            <template v-for="transaction of transactions" v-bind:key="transaction.id">
                <tr class="font-w1">
                    <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(transaction?.date) }}</td>
                    <td class="pv3 pr3 box-border-bottom tl ttc">
                        <span v-if="transaction?.subtype === 'borrowings'">{{ 'Borrowings' }}</span>
                        <span v-else>{{ transaction?.type }}</span>
                    </td>

                    <td class="pv3 pr3 box-border-bottom tl" v-if="transaction?.products?.length > 1">
                        <span class="ttc">{{ `${transaction?.productName} ` }}</span> <span> and more...</span>
                    </td>

                    <td class="pv3 pr3 box-border-bottom tl ttc" v-else>
                        <span v-if="transaction?.description?.length > 1">
                            {{ transaction?.description?.[0] }}
                            <span class="ttl">and</span>
                            {{ transaction?.description?.length - 1 }}
                            <span class="ttl">other(s)</span>
                        </span>
                        <span v-else-if="transaction?.description?.length === 1">{{ transaction?.description?.[0] }}</span>
                        <span v-else> - </span>
                    </td>

                    <template v-if="transaction.type === 'repayment'">
                        <td class="pv3 pr3 box-border-bottom tl">-</td>
                        <td class="pv3 pr3 box-border-bottom tl">
                            {{ formatAmount(transaction?.amount, $store.state.Settings?.currency) }}
                        </td>
                    </template>
                    <template v-else>
                        <td class="pv3 pr3 box-border-bottom tl">
                            {{ formatAmount(transaction?.amount, $store.state.Settings?.currency) }}
                        </td>
                        <td class="pv3 pr3 box-border-bottom tl">
                            {{ formatAmount(transaction.amountPaid, $store.state.Settings?.currency) }}
                        </td>
                    </template>

                    <td class="pv3 pr3 box-border-bottom tl">
                        {{ formatAmount(transaction?.amountOwed, $store.state.Settings?.currency) }}
                    </td>
                    <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(transaction?.dueDate) }}</td>
                </tr>
            </template>
        </tbody>
    </template>
</template>

<script>
import { reactive } from 'vue'
import { formatAmount, formatDate, sortByNumericData, sortAlphaNumericData, sortByDate } from '@/utils/lib'

export default {
    name: 'PnRSubTable',
    props: { isOpen: Boolean, transactions: Array },

    setup(props) {
        const state = reactive({
            isOpen: false
        })

        const sortTransactionsTable = (order, value) => {
            const query = {
                data: props.transactions,
                order: order,
                value: value
            }
            if (value === 'amount' || value === 'amountPaid' || value === 'amountOwed') sortByNumericData(query)
            else if (value === 'type') {
                sortAlphaNumericData(query)
            } else {
                sortByDate(query)
            }
        }
        return {
            formatAmount,
            formatDate,
            state,
            sortTransactionsTable
        }
    }
}
</script>

<style scoped>
.sort1 {
    padding: 5px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
</style>
