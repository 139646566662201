<template>
    <!-- form modal -->
    <div
        v-if="showDnCRecordPaymentModal"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showDnCRecordPaymentModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div v-if="dnCType === 'debt_repayment'" class="f3 b">Record Dept Payment for {{ entity?.fullName }}</div>
                <div v-else-if="dnCType === 'credit_repayment'" class="f3 b">Record Credit for {{ entity?.fullName }}</div>
                <div>
                    <router-link to="#">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <div class="pa3 pt0">
                <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                    <div class="pt2 w-100 w-100-l pl2-l">
                        <div class="pv2 font-w1">
                            <label for="name">
                                Amount
                                <span
                                    >(Outstanding:
                                    {{
                                        formatAmount(
                                            (entity?.totalBalance || 0) - state.debt_payment.amount,
                                            $store.state.Settings?.currency
                                        )
                                    }})</span
                                >
                            </label>
                        </div>
                        <CurrencyInput
                            class="product-input"
                            v-model="state.debt_payment.amount"
                            :options="numberFormat"
                            placeholder="0.00"
                            required
                        />
                    </div>
                    <div class="pt2 w-100 w-100-l pl2-l">
                        <div class="pv2 font-w1">
                            <label for="name">Date</label>
                        </div>
                        <input
                            type="date"
                            class="product-input"
                            v-model="state.debt_payment.date_paid"
                            placeholder="Date paid"
                            required
                        />
                    </div>
                    <div class="pt2 w-100 w-100-l pl2-l">
                        <div class="pv2 font-w1">
                            <label for="name">Payment Details</label>
                        </div>
                        <select class="product-input w-100-l w-100" v-model="state.debt_payment.glAccount" required>
                            <option value="Cash">Cash</option>
                            <option v-for="(bank, index) in bankAccounts" :key="index">
                                {{ bank?.aliasName || bank?.bankName }}
                            </option>
                        </select>
                    </div>
                    <div class="pa3 flex justify-between">
                        <button type="submit" :disabled="state.disableSubmitButton">
                            {{ state.disableSubmitButton ? 'Please wait...' : 'Record Payment' }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
import CurrencyInput from '@/components/CurrencyInput'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'DnCRecordPaymentModal',
    components: { CurrencyInput },
    props: {
        closeModal: Function
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup(props, { emit }) {
        const modalRef = ref(null)
        const store = useStore()
        const showDnCRecordPaymentModal = computed(() => store.state?.PnRModule?.showDnCRecordPaymentModal)
        const entity = computed(() => store.state?.PnRModule?.selectedEntity)
        const dnCType = computed(() => store.state?.PnRModule?.dnCType)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)
        const amountTopay = computed(() => (entity.value?.totalBalance || 0))

        const initialState = {
            debt_payment: {
                amount: 0,
                date_paid: moment().format('YYYY-MM-DD'),
                glAccount: 'Cash'
            },
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        const closeModal = () => {
            state.debt_payment.amount = entity.value?.totalAmount || 0
            state.debt_payment.date_paid = moment().format('YYYY-MM-DD')
            state.debt_payment.glAccount = 'Cash'
            props.closeModal()
        }

        const handleOnClickOutsideModal = e => {
            if (modalRef?.value && !modalRef?.value.contains(e.target)) {
                closeModal()
            }
        }

        watch(
            () => amountTopay.value,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    state.debt_payment.amount = newVal
                }
            }
        )

        const onSubmit = async () => {
            state.disableSubmitButton = true
            const amount = parseFloat(state.debt_payment.amount)
            const totalBalance = parseFloat(entity.value?.totalBalance || 0)

            if (amount === 0) {
                store.dispatch('Alert/setAlert', {
                    message: 'Amount cannot be zero',
                    status: false
                })
                state.disableSubmitButton = false
                return
            }

            if (amount > totalBalance) {
                store.dispatch('Alert/setAlert', {
                    message: `Amount, ${amount} exceeds entity total dept, ${totalBalance}`,
                    status: false
                })
                state.disableSubmitButton = false
                return
            }

            const data = {
                entity: entity.value._id,
                amount,
                subtype: dnCType.value,
                datePaid: state.debt_payment.date_paid,
                glAccounts: [
                    {
                        glAccount: state.debt_payment.glAccount,
                        glAmount: amount
                    }
                ]
            }

            await store
                .dispatch('PnRModule/recordPayment', data)
                .then(response => {
                    state.disableSubmitButton = false
                    if (response.success) closeModal()
                })
                .catch(_ => {
                    state.disableSubmitButton = false
                })
        }

        return {
            state,
            entity,
            amountTopay,
            dnCType,
            bankAccounts,
            showDnCRecordPaymentModal,
            modalRef,
            handleOnClickOutsideModal,
            onSubmit,
            formatAmount
        }
    }
}
</script>

<style>
.entity-form input[type='radio'] {
    background: unset;
    border: none;
    box-shadow: none;
    padding: unset;
    width: unset;
}
</style>
